import React from "react"

const Contact = () => {
  return (
    <section className="bg-primary">
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-md-8">
            <h4 className="uppercase mb40 mb-xs-24">Contact</h4>
            <div className="col-sm-6 p0">
              <div className="mb40 mb-xs-24">
                <h6 className="uppercase mb0">Email</h6>
                <p className="fade-half">
                  <a href="mailto:tayoo3@vt.edu">tayoo3@vt.edu</a>
                </p>
              </div>
              <div className="mb40 mb-xs-24">
                <h6 className="uppercase mb0">Phone</h6>
                <p className="fade-half">
                  <a href="tel:+1 (443) 657-3376">+1 (443) 657-3376</a>
                </p>
              </div>
            </div>
            <div className="col-sm-6 p0">
              <div className="mb40 mb-xs-24">
                <ul>
                  <li>
                    <a href="https://twitter.com/Tayo_Oladele">
                      <h6 className="uppercase">
                        <i className="ti-twitter-alt">&nbsp;</i> Twitter
                      </h6>
                    </a>
                  </li>
                  <li>
                    <a href="https://facebook.com/tayooladelet">
                      <h6 className="uppercase">
                        <i className="ti-facebook">&nbsp;</i> Facebook
                      </h6>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/heartvslogic/">
                      <h6 className="uppercase">
                        <i className="ti-instagram">&nbsp;</i> Instagram
                      </h6>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <!--end of row--> */}
      </div>
      {/* <!--end of container--> */}
    </section>
  )
}

export default Contact
